/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/material.theme.css";
@import "~@angular/material/icon/_icon-theme.scss";
button {
  cursor: pointer;
}

input {
  font-size: 14px !important;
}

.input {
  font-size: 14px !important;
}
.contenedorVesta {
  padding-top: 100px;
}

.Container .row {
  margin: 0px 0px 10px 0px !important;
}

.cellW10 {
  width: 10% !important;
}

.cellW15 {
  width: 15% !important;
}

.cellW20 {
  width: 20% !important;
}

.cellW30 {
  width: 30% !important;
}

.cellW40 {
  width: 40% !important;
}

.cellW50 {
  width: 50% !important;
}

.cellW60 {
  width: 60% !important;
}

.cellW100 {
  width: 100% !important;
}

.rowFlexNoWrap {
  display: flex !important;
  flex-direction: row !important;
  margin: unset !important;
  height: 40px;
  flex-wrap: nowrap;
}

.flexRow {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 0px;
  align-items: center;
}

/*table {
  display:grid !important;
  border-collapse: initial !important;
}*/

.buttonTransparente {
  background-color: transparent;
  border: none;
}

.rowText {
  margin: 0px 20px;
  padding: 10px 0px 0px 0px;
}

.ng-value-container {
  height: 36px !important;
  padding: 0px !important;
}

.ng-select {
  padding-bottom: 0px !important;
}

.ng-select .ng-select-container {
  color: rgba(0, 0, 0, 0.87);
  align-items: baseline;
  min-height: 36px !important;
  height: 36px !important;
}

.ng-select .ng-select-container .ng-value-container {
  border-top: unset !important;
}

.ng-dropdown-panel.ng-select-bottom {
  top: unset !important;
}

.cellGrid {
  display: grid;
  align-content: center;
  align-items: center;
}

.dialog-graph {
  z-index: 10;
}
.dialog-crear {
  z-index: 10;
}
.dialog-update {
  z-index: 10;
}
.dialog-comparacion1 {
  z-index: 11;
}
.dialog-comparacion2 {
  z-index: 12;
}

.notify-container {
  z-index: 2000 !important;
}
/*****Estilos de libreria Material UI*****/
[class*="cell-"],
[class*="col-"] :not(.mat-calendar-body-cell-container) {
  display: table-cell;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: white !important;
}

.ng-select.ng-valid
  > div.ng-select-container
  .ng-value-container
  .ng-placeholder {
  /*padding-top: .8rem;*/
  color: rgba(0, 0, 0, 0.6) !important;
}
/*
div.ng-value.ng-star-inserted{
    padding-top: .4rem!important;

}*/
.mat-datepicker-content .mat-calendar {
  width: 200px !important;
  height: 100% !important;
}

.mat-calendar-header {
  padding: 8px 8px 0 8px !important;
}

.cdk-overlay-container {
  z-index: 1500 !important;
}

.mat-dialog-container {
  padding: 0px !important;
}

.cdk-overlay-pane {
  max-width: unset !important;
}

.mat-dialog-title {
  margin: 0px !important;
  /* text-align: end;*/
  box-shadow: 0 0 6px 1px lightgray;
  /*  background-color: #0c4560;
 color: white;
  height: 10%;*/
}

.mat-dialog-content {
  display: block !important;
  margin: unset !important;
  overflow: auto;
  padding: 10px !important;
}

.mat-dialog-actions {
  padding: 8px 10px !important;
  border-top: 1px;
  height: 6%;
  min-height: 6% !important;
}
/***** Fin Estilos de libreria Material UI*****/

/****grid****/
.flexRowNoWrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 0px;
  justify-content: space-evenly;
  align-items: center;
}

.SectionGrids {
  overflow-x: auto;
  justify-content: unset;
  background: white;
  overflow-y: hidden;
  padding-top: 10px;
  position: relative;
}

.cellListP {
  padding: 0px 10px 0px 10px;
  margin: 0px 0px 0px 0px;
  width: 100%;
  display: grid;
  align-content: center;
  text-align: center;
  justify-content: center;
}

.cellListENotas {
  flex: 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cellListP input {
  /*filtros*/
  width: 100%;
}

.EncabezadoGridR1 {
  height: 30px;
  text-align: center;
  background-color: #11658e;
  color: white;
}

.EncabezadoGridR1 .cellListP {
  border: white;
  border-width: 0px 1px;
  border-style: solid;
  height: 100%;
}

.EncabezadoGridR2 {
  justify-content: space-between;
  flex-wrap: nowrap;
  height: 35px;
  background-color: white;
}

.EncabezadoGridR2 .bodyRowGrid,
.cellListP {
  /* border: lightgray;
    border-width: 0px 0.1px 0px 0px;
    border-style: solid;*/
  height: 100%;
}

.subsection {
  height: auto;
  min-height: 100px;
  background-color: #d3d3d340;
  overflow: auto;
  max-height: 220px;
}

.rowGrid {
  /*  border-radius: 10px;
*/ /*border-bottom: 1px solid;*/
  /*  margin-bottom: 4px
  border-width: 1px 1px 1px 1px;

*/
  box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.2);
  margin-bottom: 5px;
  border-style: solid;
  border-color: gray;
  background-color: white;
  min-height: 40px;
}
.label {
  z-index: 1 !important;
  border-radius: 10px;
}

.highcharts-tooltip span {
  background-color: white;
  opacity: 1;
  z-index: 9999 !important;
  border-radius: 10px;
}

.rowGrid:hover {
  background-color: lightgray;
  color: black;
}

/****grid****/

.disabled-inputs {
  pointer-events: none !important;
  opacity: 0.8 !important;
  background: lightgray !important;
}

.mat-form-field-infix {
  border-top: unset !important;
}
